





















































































































































.article {
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__filter {
    margin: 15px 0;
  }

  &__content {
    overflow-y: auto;
    flex-grow: 1;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 18px 16px;
    border-bottom: 1px solid #e7e7e7;

    &:hover {
      background-color: #fafafa;
    }
  }

  &__tag {
    margin: 0 4px;
  }

  &__settings {
    display: flex;
    align-items: center;
  }

  .router-link {
    font-weight: 400;
    &:hover {
      text-decoration: underline;
    }
  }
}
